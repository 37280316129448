<template>
    <div class="h-screen grid m-0">
        <div class="hidden md:block col-6" id="imgCol" style="background-size: cover;"></div>
        <div class="col-12 md:col-6 flex flex-column">
            <div class="flex flex-column align-items-center justify-content-center flex-grow-1">
                <div class="text-center mb-5 w-11 lg:w-6">
                    <img src="/images/logo.png" alt="Comunidae logo" height="75" class="mb-3">
                    <div class="text-900 text-3xl font-medium mb-3">{{ $appState.estate?.name }}</div>
                </div>
                <div class="w-11 lg:w-6">
                    <label for="email" class="block text-900 font-medium mb-2">Email</label>
                    <InputText v-model.trim="v$.email.$model" id="email" type="email" class="w-full mb-2" :class="{'p-invalid': v$.email.$error}" required @keyup.enter="login" />
                    <div v-for="error of v$.email.$errors" :key="error.$uid" class="mb-4">
                        <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                    </div>
                    <label for="password" class="block text-900 font-medium mb-2">Contraseña</label>
                    <InputText v-model="v$.password.$model" id="password" type="password" class="w-full mb-2" required @keyup.enter="login" />
                    <div v-for="error of v$.password.$errors" :key="error.$uid" class="mb-4">
                        <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                    </div>
                    <div class="flex align-items-center justify-content-between mb-4">
                        <router-link to="/forgot-password" class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">¿Has olvidado tu contraseña?</router-link>
                        <router-link to="/sign-up" class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">¿No tienes cuenta? Haz clic aquí.</router-link>
                    </div>
                    <div class="mb-4" v-if="loginError"><small class="p-error font-bold">{{ loginError }}</small></div>
                    <Button label="Entrar" icon="pi pi-user" class="w-full" @click="login" :disabled="canLogin"></Button>
                </div>
            </div>
            <div class="flex flex-column md:flex-row align-items-center justify-content-center gap-2 md:gap-5 mt-4 lg:mt-0">
                <a v-for="page in $appState.estate?.footerLinks" :key="page.id" :href="page.link" target="_blank" rel="noopener noreferrer">{{ page.name }}</a>
            </div>
        </div>
    </div>
</template>

<script>
import RouteGuardService from '@/service/RouteGuardService.js';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import ApiService from '@/service/ApiService.js';
import AuthService from '@/service/AuthService.js';
import ErrorReportService from '@/service/ErrorReportService.js';
import ValidationMessages from '@/validationMessages';

export default {
    beforeRouteEnter() {
        if (RouteGuardService.isLoggedIn() === true) {
            return '/';
        }

        return true;
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            email: '',
            password: '',
            authService: null,
            validationMessages: {},
            loginError: null,
            working: false,
        };
    },
    created() {
        this.authService = new AuthService();
        this.errorReportService = new ErrorReportService();
        this.validationMessages = ValidationMessages;
    },
    mounted() {
        let $el = document.querySelector('#imgCol');

        if ($el) {
            $el.style.backgroundImage = 'url(' + ApiService.getHost() + '/estates/' + this.$appState.domainSlug + '/bg)';
        }
    },
    validations() {
        return {
            email: { required, email },
            password: { required },
        };
    },
    computed: {
        canLogin() {
            return this.email.length === 0 || this.password.length === 0 || this.working;
        },
    },
    methods: {
		login() {
            if (!this.canLogin) {
                this.working = true;
                this.loginError = null;
                let w = new Worker('/pwhash-worker.js');
                let salt;
                let nonce;

                this.authService.fetchUserNonce(
                    this.$appState.visitorId,
                    window.navigator.userAgent,
                    this.email
                ).then(response => {
                    if (response.data.salt && response.data.nonce) {
                        salt = window.sodium.from_hex(response.data.salt);
                        nonce = window.sodium.from_hex(response.data.nonce);
                        w.postMessage([salt, this.password]);
                    } else {
                        this.loginError = 'ERROR L-04: Ha ocurrido un error al iniciar sesión';
                    }
                }).catch(error => {
                    console.log(error);
                    this.errorReportService.sendReport(
                        this.$appState.visitorId,
                        window.navigator.userAgent,
                        this.$appState.estate.id,
                        this.errorReportService.getRequestData(error.request, {email: this.email}),
                        this.errorReportService.getErrorData(error.response),
                    );
                    this.working = false;
                    this.loginError = 'ERROR L-01: Ha ocurrido un error al iniciar sesión';
                });

                w.onmessage = (e) => {
                    let kp = window.sodium.crypto_sign_seed_keypair(window.sodium.from_hex(e.data));
                    let nonce_signature = window.sodium.crypto_sign_detached(nonce, kp.privateKey);

                    this.authService.doLogin(
                        this.$appState.estate.id,
                        this.$appState.visitorId,
                        this.email,
                        window.sodium.to_hex(nonce),
                        window.sodium.to_hex(nonce_signature)
                    ).then(response => {
                        if (response.data.token) {
                            let afterLogin = window.sessionStorage.getItem('afterLogin');

                            if (response.data.verified) {
                                window.sessionStorage.setItem('token', response.data.token);
                                window.sessionStorage.setItem('token_abilities', JSON.stringify(response.data.token_abilities));
                                this.$appState.tokenAbilities = response.data.token_abilities;

                                if (afterLogin) {
                                    window.sessionStorage.removeItem('afterLogin');
                                    this.$router.replace(afterLogin);
                                } else {
                                    this.$router.replace({ name: 'home' });
                                }
                            } else if (afterLogin.startsWith('/email/verify')) {
                                window.sessionStorage.setItem('token', response.data.token);
                                window.sessionStorage.setItem('token_abilities', JSON.stringify(response.data.token_abilities));
                                this.$appState.tokenAbilities = response.data.token_abilities;
                                this.$router.replace(afterLogin);
                            } else {
                                this.loginError = 'ERROR L-04: Debes verificar tu email antes de iniciar sesión. Comprueba tu correo.';
                            }
                        } else {
                            this.loginError = 'ERROR L-03: Ha ocurrido un error al iniciar sesión';
                        }
                    }).catch(error => {
                        console.log(error);
                        this.errorReportService.sendReport(
                            this.$appState.visitorId,
                            window.navigator.userAgent,
                            this.$appState.estate.id,
                            this.errorReportService.getRequestData(error.request, {email: this.email}),
                            this.errorReportService.getErrorData(error.response),
                        );
                        this.working = false;
                        this.loginError = 'ERROR L-02: Ha ocurrido un error al iniciar sesión, usuario o contraseña incorrectos';
                    });
                };
            }
		}
	}
};
</script>
<style scoped>
</style>